import React from 'react'
import styled from 'styled-components'
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from '../containers/layout'
import SEO from '../components/seo'
import Container from '../containers/container'
import Section from '../containers/section'
import Attorney from '../components/attorney'

import { default as color } from '../utils/color'

const SuperText = styled.p.attrs({
  className: `f6 ttu tracked ma0 mb2`
})`
  color: ${props => props.color ? props.color : color.dark};
  text-align: ${props => props.center ? 'center' : 'left'};
`
const H2 = styled.h2.attrs({
    className: `f1 mt0 mb5 lh-solid`
  })`
    color: ${props => props.color ? props.color : color.dark};
    text-align: ${props => props.center ? 'center' : 'left'};
    margin-left: ${props => props.center ? 'auto' : '0'};
    margin-right: ${props => props.center ? 'auto' : '0'};
`
// const H3 = styled.h3.attrs({
//     className: `f4 mt0 lh-title`
//   })`
//     color: ${props => props.color ? props.color : color.dark};
//     text-align: ${props => props.center ? 'center' : 'left'};
//     margin-left: ${props => props.center ? 'auto' : '0'};
//     margin-right: ${props => props.center ? 'auto' : '0'};
// `

const AttorneyWrapper = styled.div.attrs({
  className: ``
})`
  color: white;
  
  @media (min-width: 600px) {
    display: flex;
  }

  > p, > .text-wrapper p {
    font-size: 18px;
    line-height: 23px;
    padding-left: 20px;
  }

  @media (min-width: 600px) {
    > p, div.text-wrapper {
      width: 70%;

      .gatsby-image-wrapper {
        display: block;
        margin: 0 auto;
        width: 350px !important;
      }
    }
  }
`

const Index = ({data}) => (
  <Layout>
    <SEO title="Attorneys" />
    
    <Section backgroundColor={color.dark}>
        <Container>
            <SuperText center color={color.white}>80 years of combined experience in Franklin County</SuperText>
            <H2 center color={color.white}>Meet our legal team</H2>

              <AttorneyWrapper>
                <Attorney
                    title="Joseph Aubuchon"
                    description="Retired / Of Counsel"
                    image={data.josephaubuchon.childImageSharp.fluid}
                />
                <p>Joseph Aubuchon has practiced law for over forty years. He received his Bachelor of Arts degree from Washington Univeristy in 1966 and graduated from the Univeristy of Missouri School of Law in 1969. He then served as an officer in the U.S. Army Military Intelligence in Viet Nam, emerging with the rank of captain. Mr. Aubuchon practiced law in Clayton, MIssouri, from 1971 until 1975, when he joined the Office of the Prosecuting Attorney of Franklin County. He was appointed as an Associate Circuit Judge by the Govenor of Missouri in 1978. He resumed the private practice of law in 1987. He has practiced law since that time with an emphasis in trial work.</p>
              </AttorneyWrapper>

              <AttorneyWrapper>
                <Attorney
                    title="Daniel M. Buescher"
                    description="Retired"
                    image={data.danielbuescher.childImageSharp.fluid}
                />
                  <div className="text-wrapper">
                    <p>Daniel M. Buescher retired from the practice in 2019, but continues to provide the firm with the insight that comes from a career of over 55 years in the law. Dan received his law degree from Washington University, St. Louis, Missouri in 1964, where he was third in his class, the Editor in Chief of the Law Review, and a member of the Order of the Coif Legal Honorary.</p>
                    <p>Dan proudly served as the elected Prosecuting Attorney of Franklin County for many years. He has tried countless cases to verdict in both State and Federal Court. Dan remains active in numerous civic and charitable organizations in his hometown of Washington, MO where he resides with his lovely wife, Marybelle.</p>
                  </div>
              </AttorneyWrapper>

              <AttorneyWrapper>
                <Attorney
                    title="Taylor Goodale"
                    description="Managing Partner"
                    image={data.taylorgoodale.childImageSharp.fluid}
                />
                <div className="text-wrapper">
                  <p>Taylor Goodale began practicing with Joseph Aubuchon after graduating law school from St. Louis University in 2007.  In 2014, Taylor became the managing partner and coordinated a merger with the law office of Daniel Buescher.</p>
                  <p>Taylor is active in family, domestic, juvenile law and in criminal defense, amassing countless hours in the courtroom.  Taylor has concentrated his practice on adoption law since 2014 and is an Adoption Fellow in the Academy of Adoption & Assisted Reproduction Attorneys, an exclusive group of attorneys who focus on adoption advocacy.  Taylor has helped numerous families navigate complex adoption laws to expand their families.</p>
                  <p>Taylor is an active member of the Washington, Missouri, community where he lives with his wife and two daughters.</p>
                  <Img
                    fluid={data.adoptionLogo.childImageSharp.fluid}
                    title="Adoption Logo"
                  />
                </div>
              </AttorneyWrapper>

              <AttorneyWrapper>
                <Attorney
                    title="Brandon W. Bardot"
                    description="Partner"
                    image={data.brandonbardot.childImageSharp.fluid}
                />
                <div className="text-wrapper">
                  <p>Brandon W. Bardot is a lifelong resident of Franklin County. He received his legal education at the University of Missouri – Columbia, where he graduated in the top third of his class and received recognition for best work in Criminal Law, as well as the Fred L. Howard Prize for excellence in the advancement of advocacy. Brandon earned his undergraduate degree from Southeast Missouri State University, graduating Cum Laude.</p>
                  <p>Brandon practices in a variety of areas of law including estate planning, probate, real estate, general civil litigation, and family law.</p>
                  <p>Brandon and his wife, Alesha, along with their five daughters, reside in Union, Missouri. He is an active parishioner of Immaculate Conception Church in Union, former Grand Knight of Knights of Columbus Council 1576, and a member of the Board of Directors of the Union Development Corporation.</p>
                </div>
              </AttorneyWrapper>

              <AttorneyWrapper>
                <Attorney
                    title="Jill Groff-Rosloniec"
                    description="Partner"
                    dark={true}
                    image={data.jillgroffrosloniec.childImageSharp.fluid}
                />
                <div className="text-wrapper">
                  <p>Jill Groff-Rosloniec is a Washington, Missouri, native and attended St. Francis Borgia Regional High School before earning her bachelor’s degree from St. Louis University.  She moved to Lansing, Michigan, and received her Juris Doctorate from Thomas M. Cooley Law School.  She returned to Franklin County and began her legal career with the Juvenile Court System serving as a Guardian ad Litem for the 20th Circuit for over the last ten years.  Her focus is family law, probate and juvenile law, along with serving as a Guardian ad Litem.  She is a member of the Missouri Child Justice Act Task Force.</p>
                  <p>Jill is deeply rooted in her community. She enjoys coaching youth soccer, volunteering with Downtown Washington and serving her school and parish, St. Francis Borgia, alongside her husband and children.</p>
                </div>
              </AttorneyWrapper>
        </Container>
    </Section>
  </Layout>
)

export default Index

export const query = graphql`
  query {
    josephaubuchon: file(
      relativePath: { regex: "/joseph-aubuchon/" }
    ) {
      childImageSharp {
        fluid(
          maxWidth: 300
          quality: 80
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    danielbuescher: file(
        relativePath: { regex: "/daniel-buescher/" }
      ) {
        childImageSharp {
          fluid(
            maxWidth: 300
            quality: 80
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      brandonbardot: file(
        relativePath: { regex: "/brandon-bardot/" }
      ) {
        childImageSharp {
          fluid(
            maxWidth: 300
            quality: 80
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      jillgroffrosloniec: file(
        relativePath: { regex: "/jill-groff-rosloniec/" }
      ) {
        childImageSharp {
          fluid(
            maxWidth: 300
            quality: 80
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      taylorgoodale: file(
        relativePath: { regex: "/taylor-goodale/" }
      ) {
        childImageSharp {
          fluid(
            maxWidth: 300
            quality: 80
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      adoptionLogo: file(
        relativePath: { regex: "/AAAlogofinal2018/" }
      ) {
        childImageSharp {
          fluid(
            maxWidth: 300
            quality: 80
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }
  }
`
