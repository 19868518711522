import React from 'react'
import styled from 'styled-components'
import Img from "gatsby-image"

import { default as color } from '../utils/color'

const Attorney = styled.div.attrs({
    className: `ph4 pv3 br3 overflow-hidden flex flex-column justify-end items-center mh3`
})`
    position: relative;
    height: 402px;
    width: 402px;
    margin-bottom: 30px;

    > * {
        position: relative;
        z-index: 1;
    }
`

const AttorneyBackground = styled.div.attrs({
    className: ``
})`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;

    img {
        height: 100%;
        width: 100%;
    }
`

const H3 = styled.h3.attrs({
    className: `f3 mt0 mb1 lh-solid`
  })`
    color: ${props => props.color ? props.color : color.dark};
    text-align: ${props => props.center ? 'center' : 'left'};
`

const P = styled.p.attrs({
    className: `f5 lh-copy pa0 ma0`
  })`
    color: ${props => props.color ? props.color : color.dark};
`

const Image = styled(Img)`

`

export default ({ title, description, image, dark }) => (
    <Attorney>
        <AttorneyBackground>
        {image && (
            <Image
                fluid={image}
                title={title}
            />
        )}
        </AttorneyBackground>
        <H3 color={dark ? color.dark : color.white}>{title}</H3>

        <P color={dark ? color.dark : color.white}>{description}</P>
    </Attorney>
)